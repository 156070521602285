<template>
  <div class="partner container">
    <div class="partner-title">
      <h5>{{ setLang("title") }}</h5>
      <h2>{{ setLang("titleSecond") }}</h2>
    </div>
    <div
      id="carouselExampleIndicators2"
      class="carousel slide"
      data-ride="carousel"
      data-pause=false
    >
      <div class="carousel-inner">
        <div   class="carousel-item active">
          <div class="partner-logo" id="show-slide">
            <div
              class="logo-com"
              v-for="(item, index) in partnersA"
              :key="index"
                      >
              <img :src="`${assetsPath}${item}`" alt="" />
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="partner-logo" id="show-slide">
            <div
              class="logo-com"
              v-for="(itemB, index) in partnersB"
              :key="index"
            >
              <img :src="`${assetsPath}${itemB}`" alt="" />
            </div>
          </div>
        </div>
         <div class="carousel-item">
          <div class="partner-logo" id="show-slide">
            <div
              class="logo-com"
              v-for="(itemC, index) in partnersC"
              :key="index"
            >
              <img :src="`${assetsPath}${itemC}`" alt="" />
            </div>
          </div>
        </div>
         <div class="carousel-item">
          <div class="partner-logo" id="show-slide">
            <div
              class="logo-com"
              v-for="(itemD, index) in partnersD"
              :key="index"
            >
              <img :src="`${assetsPath}${itemD}`" alt="" />
            </div>
          </div>
        </div>
      </div>

      <button style="display:none"
        class="carousel-control-prev"
        type="button"
        id="btn-click-prev1"
        data-target="#carouselExampleIndicators2"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon2" aria-hidden="true">
          <img src="../assets/home/Vector 11.svg" alt="" />
        </span>
      </button>
      <button style="display:none"
        class="carousel-control-next"
        type="button"
        id="btn-click-next1"
        data-target="#carouselExampleIndicators2"
        data-slide="next"
      >
        <span class="carousel-control-next-icon2" aria-hidden="true">
          <img src="../assets/home/Vector 12.svg" alt="" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import "../assets/style.css";
export default {
  data() {
    return {
      partnersA: {},
       partnersB: {},
       partnersC: {},
        partnersD: {},
      activeLocale: "vi",
      partner: {
        en: {
          title: "GoStream partnership",
          titleSecond: "Partners - Customers",
        },
        vi: {
          title: "GoStream hợp tác",
          titleSecond: "Đối tác - Khách hàng",
        },
      },
    };
  },
  mounted() {
    if (this.$route.params.locale == null) {
      this.activeLocale = "vi";
    } else {
      this.activeLocale = this.$route.params.locale;
    }
  },
  methods: {
    setLang(key) {
      return this.partner[this.activeLocale][key];
    },
   
  },
  created() {
    request
      .get(`partners`)
      .then((response) => {
        // console.log(response.data) 
        // let pipi = response.data
        // console.log(...pipi) 
        let posData = response.data
       this.partnersA = posData[0].PartnerLogo.map(i=> i.url)
       this.partnersB = posData[1].PartnerLogo.map(i=> i.url)
       this.partnersC = posData[2].PartnerLogo.map(i=> i.url)
       this.partnersD = posData[3].PartnerLogo.map(i=> i.url)
        console.log(this.partners) 
          // console.log( this.partners[0].PartnerLogo.map(i=> i.url))
          //  console.log( this.partners[1].PartnerLogo.map(i=> i.url))
          //   console.log( this.partners[2].PartnerLogo.map(i=> i.url))
          //    console.log( this.partners[3].PartnerLogo.map(i=> i.url))
      })
      .catch((e) => {});
  },
  computed: {
    inDex(){
      let iD = 0
      for(let i =0; i<4; i++){
       iD++
      }

    },
    assetsPath() {
      return process.env.VUE_APP_MY_ENV_ROOT_ASSETS;
      //${assetsPath}
    },
  },
};
</script>

<style></style>
